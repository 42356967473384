.container {
    padding-top: 50px;
}

.cat1 , .cat2 , .cat3 {
    font-size: 40px;
    padding-top: 50px;
    text-align: center;
    word-spacing: 60px;
    font-family: 'Times New Roman', Times, serif;
}

.jewelry {
    padding-top: 50px;
    position: relative;
    left:5rem;
}

.watch {
    margin: 0 auto;
    padding: 0;
    position: relative;
    left: 2.45rem;
    padding-top: 2rem;
}

.perfume{
    margin: 0 auto;
    padding: 0;
    position: relative;
    right: 0.2rem;
    padding-top: 2rem;
}

.perfume img{

}

.watch img{

}
img{
    display: block;
}

.brands {
    padding-top: 60px;
    position: relative;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}

.address {
    font-size: 15px;
}

@media screen and (max-width: 600px) {
    .cat1 , .cat2 , .cat3 {
        font-size: 20px;
        padding-top: 10px;
    }

    .hidden{
        display: none;
    }

    .jewelry{
        position: relative;
        padding-top: 5px;
        left: 1.5rem;
    }

    .container{
        padding-top: 35px;
    }

    .brands{
        padding-top: 20px;
    }
}

@media screen and (min-width: 600px) and (max-width: 1400px) {

    .watch{
        display: none;
    }

    .perfume{
        display: none;
    }

    .jewelry{
        position: relative;
        left: 3.1rem;
    }
}

@media screen and (max-width: 400px) {
    .cat1 , .cat2 , .cat3 {
        font-size: 15px;
        padding-top: 30px;
    }

    .container{
        padding-top: 10px;
    }

    .jewelry{
        position: relative;
        padding-top: 20px;
        left: 1.1rem;
    }

    .brands{
        padding-top: 30px;
    }
}