Footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #A1A1A1;
    color: black;
    text-align: center;
    padding-bottom: 10px;
}

Footer .name {
    font-family: 'Times New Roman', Times, serif;
    padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
}

Footer .col {
    float: left;
    width: 33.33%;
    padding: 5px;
    height: 100px;
}

@media screen and (max-width: 600px) {
    .phone , .email , .ins{
        font-size: 13px;
        height: 16px;
    }
    .email{
        position: relative;
        right: 1rem;
    }
  }

@media screen and (max-width: 400px) {
    .phone , .email , .ins{
        font-size: 11px;
        height: 15px;
    }
  }