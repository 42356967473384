.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 95%;
    height: 100%;
    justify-content: center;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .jewelry-container {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .watch-container {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .perfume-container {
    padding-top: 40px;
    padding-bottom: 80px;
  }