.topnav li a {
  color: #f2f2f2;
  font-size: 110%;
}

.topnav a:hover {
  color: silver;
}

.topnav a.active {
  background-color: none;
  color: black;
}