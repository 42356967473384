*{
  scroll-behavior: smooth;
  padding-right: 0;
  margin-right: 0;
  padding: 0;
  margin: 0;
  max-width: 100%;
}
.container-fluid{
  padding: 0;
}